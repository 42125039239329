<template>
  <div
    class="order-page"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
    <van-tabs
      v-model:active="menuIndex"
      sticky
      color="var(--van-card-price-color)"
      @change="onChange"
    >
      <template v-for="(item, index) in menus" :key="index">
        <van-tab :title="item" :badge="nums[index] > 0 ? nums[index] : ''">
          <van-pull-refresh
            v-model="refreshing"
            @refresh="onRefresh"
            :pulling-text="SYSLANG.下拉即可刷新"
            :loosing-text="SYSLANG.释放即可刷新"
            :loading-text="SYSLANG.loading"
          >
            <van-list
              v-model:loading="loading"
              :finished="finished"
              :finished-text="SYSLANG.finished"
              :loading-text="SYSLANG.loading"
              @load="getOrderList"
            >
              <ul class="order-list">
                <template v-for="(item, index) in list" :key="index">
                  <li class="order-item">
                      <view  class="coupon-item">
                                 <view class="left">
                                   <view style="height:90px;background-color: #fff;border-radius: 0px 0 30px 0px;height: 90px;">
                                     <view style="background-color:#e0ac7d;border-radius:10px 0 30px 10px;display:flex;height:90px;align-items:center;justify-content: center;">
                                       <view class="leftTitle">
                                         <!-- <view class="text-icon">赠</view> -->
                                         <view class="flex-column-center-center" style="font-size:12px;color:#fff;">
                                           <view  class="coupon-limit">{{item.codeName}}</view>
                                         </view>
                                       </view>
                                     </view>
                                   </view>  
                                 </view>
                                 <view class="right flex-row-center-center">
                                   <view class="flex-column coupon-detail" style="text-align:right;padding-right:10px;">
                                       <!-- <view class="rightTitle">{{item.name}}</view>-->
                                       <view style="text-align: center;margin-bottom:10rpx;">只可用于商品抵扣</view>
                                       <view>{{item.endDate}}</view>
                                   </view>
                                 </view>
                               <!--
                               <view wx:if="{{status == 'unused'}}" class="right flex-row-center-center" bindtap="goUse">
                                 <view class="use-btn flex-row-center-center">去使用</view>
                               </view>
                               <view wx:else class="right flex-row-center-center">
                                 <view class="right-text">{{status == 'used' ? '已使用' : '已过期'}}</view>
                               </view>
                               -->
                               </view>
							   <view v-if="menuIndex==0 && item.codeName=='束身衣抵用券10套' " class="goUse" @click="goUse()">
							     <view  class="use-btn flex-row-center-center">去使用</view>
							   </view>
							   <view v-if="menuIndex==0 && item.codeName=='束身衣抵用券5套' " class="goUse" @click="goUse()">
							     <view  class="use-btn flex-row-center-center">去使用</view>
							   </view>
							   <view v-if="menuIndex==0 && item.codeName=='意向課升級福利（2039内衣4套）'" class="goUse" @click="goUseTwo()">
							     <view  class="use-btn flex-row-center-center">去使用</view>
							   </view>
							   <view v-if="menuIndex==0 && item.codeName=='六周年限量特典礼兑换券'" class="goUse" @click="goUseThree()">
							     <view  class="use-btn flex-row-center-center">去使用</view>
							   </view>
							   <view v-if="menuIndex==0 && item.codeName=='六周年限定内衣4套'" class="goUse" @click="goUseThree()">
							     <view  class="use-btn flex-row-center-center">去使用</view>
							   </view>
							   <view v-if="menuIndex==0 && item.codeName=='六周年限定内衣12套'" class="goUse" @click="goUseThree()">
							     <view  class="use-btn flex-row-center-center">去使用</view>
							   </view>
                
                  </li>
                </template>
              </ul>
            </van-list>
          </van-pull-refresh>
        </van-tab>
      </template>
    </van-tabs>
  </div>

<!--  <div class="pc-my-page">
    <MyMenu :agentInfo="agentInfo" pagename="order" />
    <div class="main">
      <el-tabs v-model="menuIndex" class="demo-tabs" @tab-change="onChange">
        <template v-for="(item, index) in menus" :key="index">
          <el-tab-pane
            :label="item + '(' + nums[index] + ')'"
            :name="index"
          ></el-tab-pane>
        </template>
      </el-tabs>

      <div v-if="list.length > 0" class="order-list">
        <el-card class="orderitem" v-for="(item, index) in list" :key="index">
          <template #header>
            <div class="orderheader">
              <h5>{{ PAGELANG.ordernum }}: {{ item.OrderUID }}</h5>
              <h5>{{ PAGELANG.adddtime }}: {{ item.AddDTimeFormat }}</h5>
              <h5>{{ PAGELANG.paydtime }}: {{ item.PayDTimeFormat }}</h5>
              <h5>
                {{ PAGELANG.totalmoney }}: {{ SYSLANG.currency
                }}{{ item.TotalMoney }}
              </h5>
              <h5 style="margin-left: auto; margin-right: 0">
                <van-button
                  v-if="item.CurStatus == 1"
                  size="small"
                  plain
                  type="danger"
                  color="#000"
                  @click="cancelOrder(item.ID)"
                  style="margin-right: calc(100vw / 1920 * 10)"
                  >{{ PAGELANG.取消订单 }}</van-button
                >
                <van-button
                  v-if="item.CurStatus == 10 || item.CurStatus == 100"
                  size="small"
                  plain
                  type="danger"
                  color="#000"
                  @click="deleteOrder(item.ID)"
                  style="margin-right: calc(100vw / 1920 * 10)"
                  >{{ PAGELANG.删除订单 }}</van-button
                >
                <van-button
                  :to="'./order/detail?orderid=' + item.ID"
                  size="small"
                  color="#000"
                  >{{ PAGELANG.vieworder }}</van-button
                >
              </h5>
            </div>
          </template>
          <template v-if="item.ShippingList.length > 0">
            <div
              class="orderdetail"
              v-for="orderitem in item.ShippingList"
              :key="orderitem"
            >
              <div class="pdtinfo">
                <el-image
                  :src="UPLOADBASE + orderitem.GoodsRow.PdtThumb"
                  fit="cover"
                  style="width: 100px; height: 100px"
                ></el-image>

                <div style="" class="name-and-attr">
                  <span style="font-weight: bold">{{
                    orderitem.GoodsRow.PdtName
                  }}</span>
                  <span style="font-size: 12px; color: #696969">{{
                    orderitem.SelPropText
                  }}</span>
                </div>
              </div>
              <div class="pdtcount" style="width: 150px">
                <span>{{ CARTLANG.buycount }}: {{ orderitem.BuyCount }}</span>
                <span
                  >{{ CARTLANG.价格 }}: {{ SYSLANG.currency
                  }}{{ orderitem.RPriceProp }}</span
                >
                <span
                  >{{ CARTLANG.小计 }}: {{ SYSLANG.currency
                  }}{{ orderitem.TotalRPrice }}</span
                >
              </div>
              <div class="pdtcount">
                <span>{{ PAGELANG.订单状态 }}: {{ item.CurStatusText }} </span>
              </div>
            </div>
          </template>
        </el-card>
      </div>
      <van-empty
        v-else
        :image="require('@/assets/images/notdata_order.png')"
        image-size="200"
        :description="SYSLANG.finished"
      >
      </van-empty>

      <el-pagination
        v-if="pagecount > 1"
        @current-change="changePage"
        layout="prev, pager, next"
        :page-size="pagesize"
        :pager-count="pagecount"
        :total="total"
      />
    </div>
  </div> -->
</template>

<script>
import {
  Tab,
  Tabs,
  showToast,
  showLoadingToast,
  closeToast,
  showConfirmDialog,
} from "vant";
import { getCookie } from "../util";
// import MyMenu from "../components/MyMenu.vue";

export default {
  // components: {
  //   MyMenu,
  // },
  data() {
    return {
      menus: [
		  "未使用",
		  "已使用",
		  "已過期",
	  ],
      menuIndex: 0,
      nums: [],
      list: [],
      curpage: 0,
      pagecount: 0,
      pagesize: 0,
      total: 0,
      refreshing: false,
      finished: false,
      loading: false,
      agentInfo: {},
      init: true,
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }

    this.SYSLANG = this.LANG.system;
    this.PAGELANG = this.LANG.pages.order;
    this.CARTLANG = this.LANG.pages.cart;
    this.UPLOADBASE = this.uploadbase;

    showLoadingToast({
      message: this.SYSLANG.loading,
      duration: 0,
      forbidClick: true,
    });

    this.rootApp.use(Tab).use(Tabs);



    this.getMemberInfo();
  },
  methods: {
    getMemberInfo() {
      this.init = false;
	  
    },
	goUse(){
		this.$router.replace({
		  path: "/productExchange",
		});
	},
	goUseTwo(){
		this.$router.replace({
		  path: "/productExchangeTwo",
		});
	},
	goUseThree(){
		this.$router.replace({
		  path: "/productExchangeThree",
		});
	},

    onChange() {
      this.curpage = 0;
      this.list = [];
      this.pagecount = 0;
      console.log("onRefresh", this.init);
      this.getOrderList();
    },

    getOrderList() {
      if (this.init) {
        return false;
      }
      if (this.refreshing) {
        this.refreshing = false;
      }
      this.finished = true;
      this.loading = true;
      this.curpage++;
      this.init = true;
	  let orderStatus=""
	  if(this.menuIndex==0){
		  orderStatus=0
	  }
	  if(this.menuIndex==1){
	  		  orderStatus=1
	  }
	  if(this.menuIndex==2){
	  		  orderStatus=2
	  }
	  
      this.axios
        .post(this.actions.getCodeList,{
          page: this.curpage,
          status:orderStatus,
		  pageSize:100,
        })
        .then((res) => {
          closeToast();
          this.init = false;
          let { page, pageTotal, pagesize, total} = res.data.data;
		  let list=res.data.data.list
          if (res.data.code == 200) {
            this.curpage = page * 1;
            this.pagecount = pageTotal * 1;
            this.pagesize = pagesize * 1;
            this.total = total * 1;
            this.list = this.list.concat(list);
            console.log("order list ",this.list);
          } else {
            showToast({
              message:
                res.data.code == "NOTLOGIN" ? this.SYSLANG[res.data.message] : this.PAGELANG[res.data.message],
              forbidClick: true,
              onClose: () => {
                if (res.data.code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }
          this.loading = false;
          this.finished = false;
          if (this.curpage >= this.pagecount) {
            this.finished = true;
          }
        })
        .catch((error) => {
          //请求失败，触发catch中的函数 可省略
          console.log("views.Order.getOrderList.error", error);
          closeToast();
         // this.curpage--;
          //this.getOrderList();
        });
    },

    onRefresh() {
      this.loading = false;
      this.finished = false;
      this.list = [];
      this.curpage = 0;
      this.pagecount = 0;

      if (!this.init) {
        this.getOrderList();
      }
    },

    changePage(val) {
      this.curpage = val - 1;
      this.goods = [];
      this.getOrderList();
    },

    // 取消订单
    cancelOrder(orderid) {
      showConfirmDialog({
        title: this.SYSLANG.dialogtitle,
        message: this.PAGELANG.您确定要取消当前订单,
        confirmButtonText: this.SYSLANG.queding,
        cancelButtonText: this.SYSLANG.quxiao,
      })
        .then(() => {
          showLoadingToast({
            message: this.PAGELANG.正在取消订单,
            duration: 0,
            forbidClick: true,
          });
          this.axios
            .get(this.actions.order_cancel + orderid)
            .then(({ data }) => {
              closeToast();
              let { code } = data;
              if (code == "SUCCESS") {
                this.curpage = 0;
                this.list = [];
                this.pagecount = 0;
                this.getMemberInfo();
              }
            })
            .catch((error) => {
              //请求失败，触发catch中的函数 可省略
              console.log("views.Order.cancelOrder.order_cancel.error", error);
              closeToast();
            });
        })
        .catch(() => {});
    },

    // 删除订单
    deleteOrder(orderid) {
      showConfirmDialog({
        title: this.SYSLANG.dialogtitle,
        message: this.PAGELANG.您确定要删除当前订单,
        confirmButtonText: this.SYSLANG.delete,
        cancelButtonText: this.SYSLANG.quxiao,
      })
        .then(() => {
          showLoadingToast({
            message: this.PAGELANG.正在删除订单,
            duration: 0,
            forbidClick: true,
          });
          this.axios
            .post(this.actions.orderDelete,{orderCode:orderid})
            .then(({ data }) => {
              closeToast();
              let { code } = data;
              if (code == 200) {
                this.curpage = 0;
                this.list = [];
                this.pagecount = 0;
                this.getMemberInfo();
              }
            })
            .catch((error) => {
              //请求失败，触发catch中的函数 可省略
              console.log("views.Order.deleteOrder.order_delete.error", error);
              closeToast();
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style src="../assets/css/order.css" scoped></style>
<style scoped>
@media (max-width: 678px) {
	
	
	.flex-row-center-center {
	  display: flex;
	  flex-direction: row;
	  align-items: center;
	  justify-content: center;
	}
	
	.coupon-item {
	  display: flex;
	  margin-bottom: 10px;
	}
	.coupon-detail {
	  width: 200px;
	  font-size: 12px;
	  color: #fff;
	  align-items: flex-end;
	}
	.coupon-name {
	  font-size: 15px;
	  font-weight: 500;
	}
   
   .left {
     background-color: #000; 
     background-size: 100% 60%;
     background-repeat: no-repeat;
     width: 50%;
	 border-radius: 12px 12px 0px;
   }
   .left-red{
     background: #f6d5db;
     border-radius: 25px 25px 0rpx 0rpx;
     
   }
   .leftTitle{
     height:50px;
     text-align:center;
     display:flex;
     align-items:center;
     justify-content: space-between;
     margin-right:10px;
   }
   
   .leftContent{
     display: flex;
     height:60px;
     align-items: flex-start;
   }
   
   
   .right {
     background-color: #000; 
     /* background: radial-gradient(circle at top left, transparent 5px, #fff 0) top left,radial-gradient(circle at bottom left, transparent 5px, #fff 0) bottom left; */
     background-size: 100% 60%;
     background-repeat: no-repeat;
     width: 50%;
     border-radius: 0 10px 10px 0; 
     /* position: relative; */
   }
   .right-red{
     background: none;
   }
   
   .rightTitle{
       background: #fff;
       color: #000;
       border-radius: 10px;
       text-align: center;
       height: 25px;
       line-height: 25px;
       font-weight: 600;
       border: 1px solid #e0ac7d;
       margin-bottom: 5px;
       padding: 0 10px;
   }	
	
	
  .pc-my-page {
    display: none;
  }
  .order-page {
    width: 100%;
    min-height: 100vh;
    background-color: #f7f7f9;
    --van-tabs-line-height: 60px;
    --van-tab-font-size: 16px;
	padding-top: env(safe-area-inset-top);
	
  }

  .order-page .price::before {
    content: var(--aog-currency);
    font-size: 70%;
  }

  .order-page .order-list {
    padding: 3vw;
  }

  .order-page .order-list .order-item {
    padding: 3vw;
    background-color: #fff;
    border-radius: 6px;
    font-size: 14px;
    margin-bottom: 3vw;
  }

  .order-page .order-list .order-item .order-num {
    display: flex;
    height: 7vw;
    justify-content: space-between;
    font-size: 16px;
  }

  .order-page .order-list .order-item .order-num .status {
    color: #f14b6d;
  }
  .order-page .order-list .order-item .order-num .status.status-1::before {
    content: var(--aog-order-status-1);
  }
  .order-page .order-list .order-item .order-num .status.status-2::before {
    content: var(--aog-order-status-2);
  }
  .order-page .order-list .order-item .order-num .status.status-3::before {
    content: var(--aog-order-status-3);
  }
  .order-page .order-list .order-item .order-num .status.status-4::before {
    content: var(--aog-order-status-4);
    color: #4e4e4e;
  }
  .order-page .order-list .order-item .order-num .status.status-5::before {
    content: var(--aog-order-status-5);
  }

  .order-page .order-list .order-item .wuliu {
    display: flex;
    align-items: center;
    height: 40px;
    background-color: #f9f9f9;
    padding: 0 3vw;
    border-radius: 6px;
    color: #555;
  }
  .order-page .order-list .order-item .wuliu span {
    width: 0;
    flex-grow: 1;
  }

  .order-page .order-list .order-item .wuliu::after {
    content: "\e68b";
    font-family: "iconfont";
  }

  .order-page .order-list .order-item .total-money {
    line-height: 50px;
    color: #000;
    text-align: right;
    font-size: 16px;
  }
  .order-page .order-list .order-item .total-money .price {
    font-size: 22px;
    font-weight: bold;
    margin-left: 4px;
  }

  .order-page .order-list .order-item .control {
    display: flex;
    justify-content: flex-end;
    --van-button-small-height: 36px;
    --van-button-small-padding: 0 12px;
    --van-button-small-font-size: 14px;
    --van-button-primary-border-color: #d5d5d5;
    --van-button-primary-background: #1d1d1d;
    --van-button-default-background: #fe295f;
    --van-button-default-border-color: #fe295f;
    --van-button-danger-background: #fe295f;
    --van-button-default-border-color: #fe295f;
    --van-button-default-color: #fff;
  }

  .order-page .order-list .order-item .control .van-button {
    margin-left: 10px;
  }
  .goUse{
	      background: #000;
	      color: #fff;
	      width: 120px;
	      margin: auto;
	      height: 40px;
	      border-radius: 10px;
		  display: flex;
		  justify-content: center;
  }
}

@media (min-width: 679px) {
  .order-page {
    display: none;
  }
  .demo-tabs {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: #fff;
  }
  .pc-my-page .main >>> .el-tabs {
    --el-color-primary: var(--van-card-price-color);
    --el-tabs-header-height: 50px;
    --el-font-size-base: 16px;
  }
  .pc-my-page .main >>> .el-tabs .el-tabs__item {
    padding: 0 30px;
  }

  .pc-my-page .main .order-list {
    padding: 30px;
  }

  .pc-my-page .main .order-list .orderitem {
    margin-bottom: 20px;
    line-height: 24px;
    font-size: 14px;
  }
  .pc-my-page .main .order-list .orderitem >>> .el-card__header {
    padding-top: 0;
    padding-bottom: 0;
    background: #e3e3e3;
  }
  .pc-my-page .main .order-list .orderitem >>> .el-card__body {
    padding-top: 0;
    padding-bottom: 10px;
  }
  .pc-my-page .main .order-list .orderheader {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
  }
  .pc-my-page .main .order-list .orderheader h5 {
    margin-bottom: 0;
    margin-top: 0;
    line-height: 24px;
    margin-right: 20px;
  }
  .pc-my-page .main .order-list .pdtcount {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 20px;
  }
  .pc-my-page .main .order-list .pdtinfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 0;
    flex-grow: 1;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .pc-my-page .main .order-list .pdtinfo .name-and-attr {
    display: flex;
    width: 0;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 20px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .pc-my-page .main .order-list .pdtinfo .pdtimage {
    width: 100px;
    height: 100px;
  }
  .pc-my-page .main .order-list .orderdetail {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #e3e3e3;
  }
  .pc-my-page .main .order-list .orderitem .orderdetail:last-child {
    border-bottom: 0;
  }

  >>> .el-tabs__nav-next,
  >>> .el-tabs__nav-prev {
    line-height: var(--el-tabs-header-height);
  }
}
</style>
